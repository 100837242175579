<template>
  <div class="service">
    <h1 id="h1">{{ kafismaPslams.name }}</h1>
    <div v-if="kafismaPslams.text && kafismaPslams.text.length !== 0" class="service-content" v-html="kafismaPslams.text" :style="{ fontSize: fontSize + 'rem' }"></div>
    <div v-else class="service-content" :style="{ fontSize: fontSize + 'rem' }">
      <div v-html="kafismaPslams.kafismaStart"></div>
      <div v-for="item in kafismaPslams.psalms" :key="'psalm-'+item.id" :id="item.id">
        <div v-html="item.text"></div>
        <div v-if="item.slava">
          <div v-html="item.slava.text"></div>
          <div v-if="item.slava.prayer" v-html="item.slava.prayer"></div>
        </div>
      </div>
      <div v-html="kafismaPslams.kafismaEnd"></div>
    </div>
    <div @click="fontSize < 1.5 ? fontSize += 0.1 : fontSize += 0.01" class="icon-mobile txt-sz-btn txt-sz-btn-plus"><i class="fas fa-plus-circle icon-mobile plus"></i></div>
    <div @click="fontSize > 0.5 ? fontSize -= 0.1 : fontSize -= 0.01" class="icon-mobile txt-sz-btn txt-sz-btn-minus"><i class="fas fa-minus-circle icon-mobile minus"></i></div>
    <div class="service-ctrls" id="service-ctrls">
      <router-link v-if="kafismaPslams.prev" :to="{name:'kafisma', params: { block_id: this.$route.params.block_id, kafisma_id: kafismaPslams.prev }}">
        <i class="fas fa-arrow-alt-circle-left text-button"></i><i class="fas fa-chevron-left icon-mobile"></i>
      </router-link>
      <div @click="showBtns" class="icon-mobile"><i class="fas fa-text-height text-size"></i></div>
      <div @click="fontSize > 0.5 ? fontSize -= 0.1 : fontSize -= 0.01" class="text-button"><i class="fas fa-search-minus"></i></div>
      <router-link :to="{name:'psaltir', params: { block_id: this.$route.params.block_id }}"><i class="fas fa-list text-button"></i><i class="fas fa-list icon-mobile"></i></router-link>
      <div @click="fontSize < 2 ? fontSize += 0.1 : fontSize += 0.01" class="text-button"><i class="fas fa-search-plus"></i></div>
      <div @click="print" class="text-button" title="Распечатать текст"><i class="fas fa-print"></i></div>
      <router-link v-if="kafismaPslams.next" :to="{name:'kafisma', params: { block_id: this.$route.params.block_id, kafisma_id: kafismaPslams.next }}">
        <i class="fas fa-arrow-alt-circle-right text-button"></i><i class="fas fa-chevron-right icon-mobile"></i>
      </router-link>
    </div>
  </div>
</template>

<script>
import {mapState} from 'vuex';

export default {
  name: "PsaltirText",
  data: function () {
    return {
      alertShow: false,
      active: false,
      fontSize: 1,
    };
  },
  created() {
    document.title = "Помощник чтеца";
    this.fetch()
  },
  mounted() {
    if (localStorage.getItem('fontSize')) {
      try {
        this.fontSize = JSON.parse(localStorage.getItem('fontSize'));
      } catch (e) {
        localStorage.removeItem('fontSize');
      }
    }
    this.updateNames()
  },
  watch: {
    $route: 'fetch',
    fontSize(newFontSize) {
      localStorage.fontSize = newFontSize;
    },
  },
  computed: {
    ...mapState({
      kafismaPslams: state => state.kafismaPslams,
    })
  },
  methods: {
    showBtns() {
      !this.active ? Array.from(document.getElementsByClassName("txt-sz-btn")).forEach(item => item.style.right = "0") : Array.from(document.getElementsByClassName("txt-sz-btn")).forEach(item => item.style.right = "-75px");
      this.active = !this.active;
    },
    fetch() {
      this.$store.dispatch('getKafismaPslams', this.$route.params);
      window.scrollTo(0, 0);
    },
    showAlert() {
      this.alertShow = true;
      setTimeout(() => {
        this.alertShow = false
      }, 500)
    },
    updateNames() {
      setInterval(() => {
        this.$store.dispatch('getKafismaPslams', this.$route.params);
      }, 300000)
    },
    print() {
      window.print();
    }
  },
}

var prevScrollpos = window.pageYOffset;

window.onscroll = function () {
  var currentScrollPos = window.pageYOffset;
  if (prevScrollpos > currentScrollPos || currentScrollPos >= (document.documentElement.scrollHeight - document.documentElement.clientHeight - 100)) {
    if (document.getElementById("service-ctrls")) {
      document.getElementById("service-ctrls").style.bottom = "0";
    }
  } else {
    if (document.getElementById("service-ctrls")) {
      document.getElementById("service-ctrls").style.bottom = "-50px";
    }
  }
  prevScrollpos = currentScrollPos;
}

</script>

<style scoped lang="less">
@import "../assets/vars";

.service {
  -webkit-tap-highlight-color: rgba(0, 0, 0, 0);
  h1 {
    font-family: "Cyrillic Old", sans-serif;
    margin: 0 0 1em 0;
    padding: 0;
    text-align: center;
    font-size: 1.5rem;
    color: @color-h;
  }
  .service-content {
    font-family: "Hirmos UCS", sans-serif;
    line-height: 1.5;
    &.rus {
      font-family: Arial, sans-serif;
    }

    /deep/ h1  {
      margin: 0 0 1em 0;
      padding: 0;
      text-align: center;
      font-size: 1.5rem;
      color: @color-h;
    }

    /deep/ h2, /deep/ h3, /deep/ h4, /deep/ h5 {
      color: red;
      font-size: 1rem;
    }

    /deep/ .cyr {
      font-family: "Cyrillic Old", sans-serif;
    }

    /deep/ .red {
      color: red;
    }

    /deep/ .reader {
      color: #00cccc;
    }

    /deep/ .number {
      color: #00cccc;
      font-family: "Cyrillic Old", sans-serif;
    }

    /deep/ .rus {
      font-family: Arial, sans-serif;
    }

    p {
      margin-block-start: 10px;
      margin-block-end: 10px;
    }

    /deep/ details {
      font-family: Arial, sans-serif;
      font-size: .8em;
      padding: .5rem;
      display: block;
      border: 1px solid #70394c;
      border-radius: .5rem;

      & summary {
        cursor: pointer;
        color: #70394c;
      }

      &[open] summary {
        border-bottom: 1px solid #70394c;
        padding-bottom: .2rem;
      }
    }

  }

  .service-ctrls {
    opacity: 0.95;
    position: fixed;
    display: flex;
    right: 0;
    left: 0;
    justify-content:space-between;
    bottom: 0;
    background-color: #70394c;
    transition: bottom 0.3s;
    .text-button:hover {
      color: red;
    }
    a, .text-button, .text-size {
      font-size: 1.2rem;
      padding: .2rem;
      color: white;
      text-decoration: none;
      cursor: pointer;
    }
    .fa-search-plus, .fa-search-minus {
      font-size: 1.1rem;
    }
    @media (min-width: 800px) {
      width: 800px;
      left: auto;
      right: auto;
      margin-left: -1em;
    }
  }
  .icon-mobile {
    display: none;
  }
  @media (max-width: 767px) {
    .service-ctrls {
      justify-content: space-around;
    }
    .icon-mobile {
      display:flex;
      font-size: 1.4rem;
    }
    .text-button {
      display: none;
    }
  }
  .txt-sz-btn {
    position: fixed;
    right: -75px;
    bottom: 140px;
    color: #70394c;
    text-align: center;
    transition: right 0.3s;
    @media (max-width: 767px) {
      .minus, .plus {
        font-size: 3rem;
      }
    }
  }
  .txt-sz-btn-minus {
    bottom: 55px;
  }


  .alert-btn {
    cursor: pointer;
    position: fixed;
    font-size: 1.4rem;
    width: 2rem;
    height: 2rem;
    line-height: 2rem;
    text-align: center;
    bottom: 1rem;
    right: 1rem;
    background-color: @color-h;
    color: @color-l;
    border-radius: 50%;
  }

  @keyframes blink {
    from {
      opacity: 0
    }
    to {
      opacity: 1
    }
  }

  .alert-bg {
    background: red;
    position: fixed;
    opacity: 0;
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
    animation: blink 100ms infinite alternate;
  }

  @media print {
    .service-ctrls {
      display: none;
    }
    .service-content {
      font-size: 1rem;
    }
  }
}
</style>
